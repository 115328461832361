#recherche,#crudrecherche,#addrecherche,#editrecherche,#deleterecherche,#searchrecherche{
    display: none;
}

#ouirechercheBtn{
    margin-top: 4%;
    flex: 1;
    width: 120px;
    height: 35px;
    text-align: center;
    align-content: center;
    background-color: #008000;
    border: 1;
    border-radius: 15px;
    color: #ffffff;
}
#ouirechercheBtn:hover {
    background: #99ff99;
}

#nonrechercheBtn{
    margin-top: 4%;
    flex: 1;
    width: 120px;
    height: 35px;
    text-align: center;
    align-content: center;
    background-color: #7e0926;
    border: 1;
    border-radius: 15px;
    color: #ffffff;
}
#nonrechercheBtn:hover {
    background: #da62c6;
}