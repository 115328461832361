#moteur,#crudmoteur,#addwikipedia{
    display: none;
}

.texte{
    width: 90%;
    padding: 16px;
    margin-bottom: 16px;
    /*background-color: #ffffff;*/
    border-radius: 16px;
    transition: 0.4s;
}

.texte p{
    color: #313131;
    font-size: 18px;
    margin-bottom: 16px;
}

/*.texte p{
    font-weight: 700;
    color: #A84FFF;
}*/

.results{
    max-width: 768px;
    margin: 0 auto;
}

.result{
    width: 90%;
    padding: 16px;
    margin-bottom: 16px;
    background-color: #ffffff;
    border-radius: 16px;
    transition: 0.4s;
}

.result:hover{
    box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.2);
}

.result h3{
    color: #888;
    font-size: 28px;
    margin-bottom: 16px;
}

.result p{
    color: #313131;
    font-size: 18px;
    margin-bottom: 16px;
}

.result p .searchmatch{
    font-weight: 700;
    color: #A84FFF;
}

.result a{
    display: inline-block;
    padding: 12px 16px;
    background-color: #A84FFF;
    color: #ffffff;
    font-weight: 700;
    text-decoration: none;
    border-radius: 12px;
    transition: 0.4s;
}

.result a:hover{
    background-color: #FF4FA8;
}