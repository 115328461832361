#notes,#crudnotes,#addnotes,#editnotes,#deletenotes,#searchnotes{
    display: none;
}

#ouinotesBtn{
    margin-top: 4%;
    flex: 1;
    width: 120px;
    height: 35px;
    text-align: center;
    align-content: center;
    background-color: #008000;
    border: 1;
    border-radius: 15px;
    color: #ffffff;
}
#ouinotesBtn:hover {
    background: #99ff99;
}

#modifnotesBtn{
    margin-top: 4%;
    flex: 1;
    width: 120px;
    height: 35px;
    text-align: center;
    align-content: center;
    background-color: #4d493d;
    border: 1;
    border-radius: 15px;
    color: #ffffff;
}
#modifnotesBtn:hover {
    background: #b49b55;
}

#nonnotesBtn{
    margin-top: 4%;
    flex: 1;
    width: 120px;
    height: 35px;
    text-align: center;
    align-content: center;
    background-color: #7e0926;
    border: 1;
    border-radius: 15px;
    color: #ffffff;
}
#nonnotesBtn:hover {
    background: #da62c6;
}