.details{
    font-size: medium;
    font-weight: bold;
}
.detailtitre{
    font-size: large;
    font-weight: bold;
    color:#008000;
}

#detailuser{
    display: none;
}